import React from 'react';

function Updates() {
  return (
    <section className="o-hidden">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-marq lrg opacity-4">
              <div className="slide-har st1 strok">
                <div className="box">
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                </div>
                <div className="box">
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                  <div className="item">
                    <h4>Free Support</h4>
                  </div>
                  <div className="item">
                    <h4>Lifetime Updates</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Updates;
